import { type Pod } from '@/app/_types/pod';
import { env } from '@/env';
import { type CreatePodInput } from '@/server/api/routers/pods';
import posthog from 'posthog-js';

const trackedRoutes = [
  '/',
  '/dashboard',
  '/dashboard/create-cluster',
  '/dashboard/create-cluster/search',
  '/dashboard/clusters',
  '/dashboard/instances',
  '/dashboard/quotes',
  '/dashboard/profile',
  '/dashboard/providers',
  '/dashboard/support',
  '/dashboard/billing',
  '/dashboard/team',
  '/dashboard/tokens',
  '/intelligence',
];

export class PostHogTracking {
  public postHogClient: typeof posthog | undefined = undefined;

  private static _instance: PostHogTracking;
  private _enabled = false;

  public static getInstance(): PostHogTracking {
    if (!PostHogTracking._instance) {
      PostHogTracking._instance = new PostHogTracking();
    }
    return PostHogTracking._instance;
  }

  private constructor() {
    if (typeof window === 'undefined') {
      return;
    }
    if (PostHogTracking._instance) {
      throw new Error(
        'Instance creation of PostHogTracking is not allowed. Use PostHogTracking.getInstance()',
      );
    }

    if (env.NEXT_PUBLIC_POSTHOG_KEY) {
      posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
        api_host: '/ingest',
        ui_host: 'https://eu.i.posthog.com',
        capture_pageview: false,
        capture_pageleave: false,
      });
      this._enabled = true;
      this.postHogClient = posthog;
    }
  }

  public track(event: string, properties: object = {}) {
    if (!this._enabled) {
      return;
    }
    posthog.capture(event, properties);
  }

  public identify(id: string) {
    if (!this._enabled) {
      return;
    }
    posthog.identify(id);
  }

  public alias(email: string, id: string) {
    if (!this._enabled) {
      return;
    }
    posthog.alias(email, id);
  }

  public setUserProperties(properties: object) {
    if (!this._enabled) {
      return;
    }
    posthog.people.set(properties);
  }

  public pageView(currentUrl: string, previousUrl: string | null | undefined) {
    if (trackedRoutes.includes(currentUrl)) {
      // prettier-ignore
      if (previousUrl) {
        this.track('$pageleave', {'$current_url': previousUrl});
      }
      // prettier-ignore
      this.track('$pageview', { '$current_url': currentUrl });
    }
  }
  public podOrdered(
    podName: string,
    podDetails: CreatePodInput,
    podPriceHr: number | undefined | null,
    podUID: string,
    userEmail: string | undefined | null,
    userId: string | null,
    isMultinode = false,
  ) {
    if (!this._enabled) {
      return;
    }

    if (userId && userEmail) {
      this.identify(userId);
      this.alias(userEmail, userId);
    }

    this.track('pod_ordered', {
      podName: podName,
      podDetails: podDetails,
      podPriceHr: podPriceHr,
      podUID: podUID,
      multinode: isMultinode,
    });
    if (isMultinode) {
      this.setUserProperties({ multinode_orderer: true });
    } else {
      this.setUserProperties({ pod_orderer: true });
    }
  }

  public podTerminated(
    podName: string,
    podDetails: Pod,
    podPriceHr: number | undefined | null,
    podUID: string,
    uptime: number,
  ) {
    this.track('pod_terminated', {
      podName: podName,
      podPriceHr: podPriceHr,
      podUID: podUID,
      uptime: uptime,
    });
  }

  public couponCodeRedeemed(couponId: string) {
    if (!this._enabled) {
      return;
    }
    this.track('coupon_code_redeemed', {
      coupon_id: couponId,
    });
    this.setUserProperties({ has_used_coupon: true });
  }

  public autoTopupEvent(
    action: 'enable' | 'update' | 'pause' | 'cancel',
    trigger?: number,
    value?: number,
  ) {
    if (!this._enabled) {
      return;
    }

    this.setUserProperties({
      auto_topup_active: ['enable', 'update'].includes(action),
      auto_topup_trigger: trigger,
      auto_topup_value: value,
    });

    this.track(`auto_topup_${action}`, {
      auto_topup_trigger: trigger,
      auto_topup_value: value,
    });
  }

  public providerKeyAdded(provider: string) {
    this.track('provider_added', { provider: provider });
  }

  public setUser(
    id: string,
    email: string,
    fname: string,
    lname: string,
    imageUrl: string,
  ) {
    this.identify(id);
    this.alias(email, id);
    this.setUserProperties({
      // prettier-ignore
      '$email': email,
      // prettier-ignore
      '$first_name': fname,
      // prettier-ignore
      '$last_name': lname,
      // prettier-ignore
      '$avatar': imageUrl,
    });
  }

  public signUpEvent(userData: { email?: string; id: string }) {
    if (!this._enabled) {
      return;
    }
    this.identify(userData.id);
    this.track('sign_up', { email: userData.email, id: userData.id });
    // prettier-ignore
    this.setUserProperties({ '$first_login': new Date().toISOString() });
  }

  public signInEvent(userData: { email?: string; id: string }) {
    if (!this._enabled) {
      return;
    }
    this.identify(userData.id);
    this.track('sign_in', { email: userData.email, id: userData.id });
    // prettier-ignore
    this.setUserProperties({ '$last_login': new Date().toISOString() });
  }

  public signOut() {
    if (!this._enabled) {
      return;
    }
    this.track('sign_out');
    posthog.reset();
  }

  public people(id: string, payload: object) {
    if (!this._enabled) {
      return;
    }
    this.identify(id); // Ensure the user is identified before setting properties
    posthog.people.set(payload);
  }

  public identifyUser(id: string) {
    this.identify(id);
  }

  public teamCreated(teamData: { id?: string; name: string }) {
    if (!this._enabled) {
      return;
    }
    this.track('team_created', { id: teamData.id, name: teamData.name });
    this.setUserProperties({ last_team_created: new Date().toISOString() });
  }
  public teamInvitationCreated(email: string) {
    if (!this._enabled) {
      return;
    }
    this.track('team_invitation_created', { invited_email: email });
  }
}
